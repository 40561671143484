import React from 'react';
// import Main from '../components/adminDashboard/main/Main';
import Sidebar from "../components/adminDashboard/sidebar/Sidebar";


const AdminDashboard = () => {

  return (
    <div className="container1">
      <Sidebar />
    </div>
  );
}

export default AdminDashboard;
